import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { Slider } from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './styles/reset.css'

Vue.config.productionTip = false;
// Vue.component(Slider.name, Slider)

// console.log('object :>> ', Slider);
// Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
