<template>
  <div class="home">
    <div class="cover">
      <img v-if="audioInfo.logo !== ''" :src="audioInfo.logo" alt="" />
      <p v-else>加载中...</p>
    </div>
    <div class="content">
      <div style="display: flex; padding: 15px 0">
        <div class="tag">
          <span class="tag-name">{{ audioInfo.type_name }}</span>
        </div>
        <div class="name">{{ audioInfo.title }}</div>
      </div>
      <p class="history">100个物种故事 | {{ audioInfo.name }}</p>
      <div v-if="audioInfo.file_url">
        <!-- <audio-player :audioUrl="audioInfo.file_url" /> -->
        <audio
          ref="audio"
          :src="audioInfo.file_url"
          controls
          style="width: 100%"
          muted="false"
          autoplay
        ></audio>
      </div>
    </div>
    <div class="synopsis">
      <p class="tabs">
        <span class="tabs-item" :class="{ sel: isSel }" @click="choose(1)"
          >简介</span
        >
        <span class="tabs-item" :class="{ sel: !isSel }" @click="choose(2)"
          >目录</span
        >
      </p>
      <div class="description" v-show="isSel">
        <img :src="audioInfo.images" alt="" />
      </div>
      <div v-show="!isSel">
        <div
          v-for="(item, index) in audioList"
          :key="item.id"
          class="audio-item"
          @click="toAudio(item.id)"
        >
          <p>{{ index + 1 }}</p>
          <div>
            <h3>{{ item.title }}</h3>
            <div class="total">播放: {{ item.num }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { throttle } from "lodash";
// @ is an alias to /src
// import AudioPlayer from "@/components/audio-reset";

export default {
  name: "Home",
  // components: {
  //   AudioPlayer,
  // },
  data() {
    return {
      audioInfo: {
        file_url: "",
        logo: "",
        title: "",
        images: "",
        type_name: "",
        name: "",
      },
      isSel: true,
      audioList: [],
    };
  },
  methods: {
    choose(type) {
      if (type === 1) {
        this.isSel = true;
      } else {
        this.isSel = false;
      }
    },
    toAudio(id) {
      this.getInfo(id);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        this.isSel = true;
      }, 400);
      this.getAudioList();
    },
    getInfo(id) {
      let self = this;
      axios({
        method: "get",
        // url: "http://localhost:8080/api/InfoRead/info",
        url: "https://wuzhong.gzsdsk.com/api/InfoRead/info",
        params: { id },
      }).then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data;
          // console.log("data :>> ", data);
          const { file_url, logo, title, images, type_name, name } = data;
          self.audioInfo = {
            file_url,
            logo,
            title,
            images,
            type_name,
            name,
          };
        } else {
        }
      });
    },
    getAudioList() {
      let self = this;
      axios({
        method: "get",
        url: "https://wuzhong.gzsdsk.com/api/InfoRead/list",
      }).then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data;
          // console.log("objectt :>> ", data);
          self.audioList = data;
        } else {
        }
      });
    },
    // handleScroll: throttle(function (e) {
    //   let self = this;
    //   let scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   if (scrollTop >= 280) {
    //     self.isSel = true;
    //   } else {
    //     self.isSel = false;
    //   }
    // }, 100),
  },
  mounted() {
    let id = this.$route.query.id;
    this.getInfo(id);
    this.getAudioList();
  },
};
</script>
<style scoped lang='less'>
.home {
  background: #f8f9fb;
}
// .header {
//   height: 40px;
//   // background: rgba(204, 204, 204, 0.2);
//   padding: 0 10px;
//   line-height: 40px;
//   color: #fff;
//   position: fixed;
//   z-index: 99999999;
//   top: 0;
//   left: 0;
//   right: 0;
//   font-weight: 500;
// }
.cover {
  width: 100%;
  height: 300px;
  background: #ccc;
  img {
    width: 100%;
    height: 100%;
  }
}
.content {
  padding: 0 20px 20px 20px;
  background: #fff;
  margin-bottom: 10px;
  .tag {
    width: 84px;
    height: 30px;
    background: #eefff0;
    border-radius: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-name {
      font-size: 13px;
      font-weight: 400;
      color: #39de6a;
      letter-spacing: 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-name::after {
      content: "";
      width: 10px;
      height: 2px;
      background: #39de6a;
      margin-top: 3px;
      border-radius: 2px;
    }
  }
  .name {
    font-size: 18px;
    font-weight: bold;
  }
  .history {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #bbbac0;
    margin-bottom: 15px;
  }
}

.synopsis {
  padding-bottom: 10px;
  background: #fff;
  .tabs {
    height: 43px;
    padding: 0 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &-item {
      font-size: 18px;
      font-weight: 400;
      color: #2a2a2a;
    }
    .sel {
      font-weight: bold;
      color: #2a2a2a;
      position: relative;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
    }
    .sel::after {
      content: "";
      height: 4px;
      width: 25px;
      background: #39de6a;
      border-radius: 4px;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .description {
    img {
      width: 100%;
    }
  }
}
.audio-item {
  display: flex;
  padding: 15px 0;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #ddd;

  p {
    width: 20px;
    line-height: 35px;
    font-weight: 400;
    color: #bbbac0;
    margin-right: 5px;
  }
  h3 {
    font-weight: 400;
    color: #393939;
    letter-spacing: 2px;
    margin-bottom: 5px;
  }
  .total {
    font-size: 10px;
    font-weight: 400;
    color: #bbbac0;
  }
}
.audio-item:last-child {
  border: none;
}
</style>
